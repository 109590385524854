import styled from 'styled-components'

const padding = 15

export const StyledListitem = styled.div`
  position: relative;
  width: calc(100% - ${padding * 2}px);
  height: 75px;
  text-align: left;
  background-color: rgb(50, 50, 50);
  padding: 0 ${padding}px;
  margin-bottom: ${padding}px;
  .songName {
    display: inline-block;
    width: 100%;
    a {
      text-decoration: none;
      color: rgb(210, 210, 210);
      font-size: 30px;
      float: left;
      font-weight: bold;
    }
    
    .author {
      margin-top: 5px;
      float: right;
      font-style: italic;
    }

    a:visited {
      color: rgb(210, 210, 210);
    }
    a:hover {
      color: rgb(150, 150, 150);
    }
  }
  .songInfo {
    position:absolute;
    display: inline-block;
    width:calc(100% - ${padding * 2}px);
    bottom: ${padding/2}px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    
    div {
      margin-right: 20px;
    }

    .instruments {
      position: absolute;
      right: 0;
      margin-right:0px;
      font-style: italic;
    }
  }
`;