import React from 'react'
import { StyledListitem } from './Listitem.styled'
import { Link } from 'react-router-dom'

const Listitem = ({song}) => {
    return (
        <StyledListitem>
            <div className="songName">
                <Link to={`/song/${song.id}`} >
                    {song.name}
                </Link>
                <div className="author">{`By: ${song.author}`}</div>
            </div>
            {
                song.author ? (
                    <div className="songInfo">
                        <div className="timeSignature">{`Time Signature: ${song.formatting.timeSignature.beats}/${song.formatting.timeSignature.base}`}</div>
                        <div className="tempo">{`Tempo: ${song.formatting.tempo}`}</div>
                        <div className="isMIDI">{`MIDI: ${song.isMidi ? 'Yes' : 'No'}`}</div>
                        <div className="hasMP3">{`Mp3: ${song.hasMp3 ? 'Yes' : 'No'}`}</div>
                        <div className="likes">{`PowerPoints: ${song.likes ? song.likes : 0}`}</div>
                        <div className="instruments">{ song.formatting.instruments ? song.formatting.instruments.join(', ') : '' }</div>
                    </div>
                ) : (
                    <div className="songInfo">
                        Loading...
                    </div>
                )
            }
            
        </StyledListitem>
    )
}

export default Listitem