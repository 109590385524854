import React, { useEffect } from 'react'
import { StyledSonglist } from './Songlist.styled'
import Listitem from './Listitem/Listitem'
import { useState } from 'react'
import { distance } from 'fastest-levenshtein'

const Songlist = ({ songs, sorting = false }) => {
    const [displayed, setDisplayed] = useState([])
    const [filtered, setFiltered] = useState([])
    const [safeSongs, setSafeSongs] = useState([])
    const [tags, setTags] = useState({})
    const [sort, setSort] = useState('likes:+')

    const availableTags = ['solo', 'duet', 'band', 'easy', 'medium', 'hard', 'expert']

    useEffect(() => {
        if (songs && songs.length > 0) {
            const safe = songs.map(song => {
                if (Array.isArray(song.likes) || song.likes === undefined) {
                    const s = JSON.parse(JSON.stringify(song))
                    s.likes = s.likes ? s.likes.length : 0
                    return s
                }
                return song
            })
            safe.sort((a, b) => { return b.likes - a.likes })
            setSafeSongs(safe)
            setFiltered(safe)
        }
    }, [songs])

    useEffect(() => {
        const allSongs = JSON.parse(JSON.stringify(safeSongs))
        let filteredSongs = []
        const t = Object.values(tags)

        if (t.length > 0) {
            allSongs.forEach(s => {
                let allow = true
                for (let i = 0; i < t.length; i++) {
                    if (s.tags === undefined || s.tags[t[i]] === undefined) {
                        allow = false
                        break
                    }
                }

                if (allow) filteredSongs.push(s)
            })
        }
        else {
            filteredSongs = allSongs
        }

        if (sort?.length > 0) {
            const [key, direction] = sort.split(':')
            const descending = direction === '+' ? true : false
            filteredSongs.sort((a, b) => {
                let aVal = a[key]
                let bVal = b[key]
                if (aVal === undefined) aVal = 0
                if (bVal === undefined) bVal = 0

                return descending ? b[key] - a[key] : a[key] - b[key]
            })
        }

        setFiltered(filteredSongs)
        setDisplayed(filteredSongs)

    }, [tags, sort, safeSongs])

    const filter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            const search = e.target.innerText.toLowerCase()
            if (typeof search === 'string' && search.length > 0) {
                const searchParts = search.split(/\s+/ig)
                const result = filtered.map((song, index) => {
                    const name = song.name.toLowerCase()
                    const nameParts = name.split(/\s+/ig)
                    const similarityScore = searchParts.reduce((score, s) => {
                        if (name.includes(s))
                            score += 2
                        nameParts.forEach(n => {
                            if (distance(n, s) < 2)
                                score++
                        })
                        return score
                    }, 0)
                    return {
                        index: index,
                        score: similarityScore,
                        distance: distance(search, name),
                        song: song
                    }
                })
                result.sort((a, b) => {
                    const scoreDiff = b.score - a.score
                    if (scoreDiff !== 0)
                        return scoreDiff
                    return a.distance - b.distance
                })
                const filter = result.reduce((sum, val) => {
                    sum.push(val.song)
                    return sum
                }, [])
                setDisplayed(filter)
            }
            e.target.innerHTML = ''
            return false
        }
    }

    const preventDiv = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            return false
        }
    }

    const sortSongs = (e) => {
        setSort(e.target.value)
    }

    const toggleTag = (e) => {
        const tag = e.target.innerText
        const hasTag = tags[tag] ? true : false
        const t = JSON.parse(JSON.stringify(tags))
        if (hasTag) {
            delete t[tag]
            e.target.style.borderColor = ''
        }
        else {
            t[tag] = tag
            e.target.style.borderColor = 'darkgreen'
        }
        setTags(t)
    }

    return (
        <StyledSonglist>
            <div className="search">
                <div placeholder="Search" className="text" onKeyDown={preventDiv} onKeyUp={filter} contentEditable />
                {
                    sorting && (<select className="sortOptionSelect" onChange={sortSongs}>
                        <option className="sortOption" value="likes:+">Points</option>
                        <option className="sortOption" value="createdDate:+">Newest</option>
                        <option className="sortOption" value="createdDate:-">Oldest</option>
                        <option className="sortOption" value="lastModified:+">Updated</option>
                    </select>)
                }
            </div>
            <div className="tags">
                {
                    availableTags.map((tag, index) => {
                        return (
                            <div className="tag" onClick={toggleTag} key={index}>{tag}</div>
                        )
                    })
                }
            </div>
            {
                displayed.length === 0 ? <span style={{ padding: '20px' }}>Loading...</span> : displayed.map((song, index) => {
                    return (
                        <Listitem key={`songListItem-${index}`} song={song}></Listitem>
                    )
                })
            }
        </StyledSonglist>
    )
}

export default Songlist