import styled from 'styled-components'

export const StyledVisualizer = styled.main`
position: relative;
display:flex;
flex-direction: column;
justify-content: center;
text-align: left;
z-index: 0;
width:100%;
min-width: 600px;

.fill {
    float:left;
    clear:both;
    height:20px;
    width:100%;
}
.fillTop {
    float:left;
    clear:both;
    height:20px;
    width:100%;
}

.tab {
    float:left;
    border: 1px black solid;
    min-height:390px;
    min-width: 600px;
    width: 75%;
    margin-left: calc(12.5% - 20px);
    padding: 20px 20px;
    margin-top:30px;
    background-color: rgb(40, 40, 40);
    white-space: pre-wrap;
    color:rgb(200, 200, 200)
}

.songInfo {
    position:relative;
    min-width:600px;
    width: 75%;
}
`;