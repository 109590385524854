import React from 'react'
import { StyledAbout } from './about.styled'

const About = () => {
    return (
        <StyledAbout>
        </StyledAbout>
    )
}

export default About