import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { StyledSongdetails } from './Songdetails.styled'
import Firebase from '../../../firebase'

const Songdetails = ({ song }) => {
    const { songId } = useParams()
    const history = useHistory()
    const [mp3, setMp3] = useState('')
    const [pinned, setPinned] = useState(false)
    const audioRef = useRef()
    let auth = localStorage.getItem('authenticated')
    auth = auth ? JSON.parse(auth) : auth

    useEffect(() => {
        const cached_mp3 = `song/mp3/${songId}`
        const cache_name = 'gw2-songbook'
        const cache_time = 14 * 24 * 3600 * 1000
        const blobUrls = []
        if (song.hasMp3) {
            const firebase = new Firebase()

            const lastFetch = localStorage.getItem(`${songId}-mp3-fetched`) || 0
            const now = new Date().getTime()
            const shouldFetch = (now - lastFetch) > cache_time

            window.caches.match(cached_mp3).then(async cache => {
                if (cache && !shouldFetch) {
                    console.log('Cached Mp3')
                    const mp3 = await cache.blob()
                    const mp3Url = URL.createObjectURL(mp3)
                    blobUrls.push(mp3Url)
                    setMp3(mp3Url)
                }
                else {
                    firebase.app.storage().ref(`songs/${songId}/mp3.mp3`).getDownloadURL().then(url => {
                        const xhr = new XMLHttpRequest()
                        xhr.responseType = 'blob'
                        xhr.onreadystatechange = (e) => {
                            if (e.target.readyState === 4 && [200, 204].indexOf(e.target.status) !== -1) {
                                const mp3 = e.target.response
                                window.caches.open(cache_name).then(async store => {
                                    const mp3Blob = new Blob([mp3], { type: "audio/mpeg" })
                                    store.put(cached_mp3, new Response(mp3Blob), { status: 200 }).then(() => {
                                        localStorage.setItem(`${songId}-mp3-fetched`, new Date().getTime())
                                        console.log('Updated Mp3')
                                    }).catch(console.log)
                                })
                                const mp3Url = URL.createObjectURL(mp3)
                                blobUrls.push(mp3Url)
                                console.log(mp3Url)
                                setMp3(mp3Url)
                            }
                        }
                        xhr.open('GET', url)
                        xhr.send()
                    }).catch(console.log)
                }
            })
        }
        return () => {
            blobUrls.forEach(url => {
                URL.revokeObjectURL(url)
            })
        }
    }, [songId, song])

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = 0.3
        }
    }, [mp3])

    const changeLike = async () => {
        const firebase = new Firebase()
        const method = song.likes?.includes(auth.uid) ?
            firebase.app.functions('europe-west1').httpsCallable('delikesongv2') :
            firebase.app.functions('europe-west1').httpsCallable('likesongv2')
        await method({ id: song.id }).catch((err) => { console.log(err); return false })
    }

    const changePinned = () => { setPinned(!pinned) }

    return (
        <StyledSongdetails className={pinned ? 'pinned' : ''}>
            <div className="songName">
                {song.name}
                <div className="author">{`By: ${song.author}`}</div>
            </div>
            {
                song.author ? (
                    <div className="songInfo">
                        <div className="timeSignature">{`Time Signature: ${song.formatting.timeSignature.beats}/${song.formatting.timeSignature.base}`}</div>
                        <div className="tempo">{`Tempo: ${song.formatting.tempo}`}</div>
                        <div className="isMIDI">{`MIDI: ${song.isMidi ? 'Yes' : 'No'}`}</div>
                        <div className="hasMP3">{`Mp3: ${song.hasMp3 ? 'Yes' : 'No'}`}</div>
                        <div className="likes">{`PowerPoints: ${song.likes ? song.likes.length : 0}`}</div>
                    </div>
                ) : (
                    <div className="songInfo">
                        Loading...
                    </div>
                )
            }
            {
                <div className={pinned ? 'pin isPinned' : 'pin'} onClick={changePinned}>📌</div>
            }
            {
                auth?.uid && <div className={song.likes?.includes(auth.uid) ? 'PowerPoints liked' : 'PowerPoints notLiked'} onClick={changeLike}>Pp</div>
            }
            {
                mp3.length > 0 ? (
                    <audio ref={audioRef} className="mp3" controls src={mp3}></audio>
                ) : ''
            }
            {
                song.author ? (
                    <div className="button" onClick={() => { history.push(`/post/${songId}`) }} >Edit Song</div>
                ) : ''
            }
        </StyledSongdetails>
    )
}

export default Songdetails