import React, { useEffect, createRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Firebase from '../../firebase'
import { StyledMidiPost } from './midipost.styled.js'
import { Midi } from '@tonejs/midi'

const settings = {
    introBars: 0,
    barsPerRow: 4,
    octaveOffset: 0,
    beatNote: 'time',
    steps: 0
};

const MidiPost = ({ post }) => {
    const firebase = new Firebase()
    const history = useHistory()
    const { songId } = useParams()
    const tabRef = createRef()
    const title = createRef()
    const author = createRef()
    const tempo = createRef()
    const timeSignature = createRef()
    const deleteButton = createRef()
    const midiFileRef = createRef()
    const mp3File = createRef()
    let mp3 = undefined
    let midi = undefined
    let isPosting = false
    const zeroWidthSpace = '​'
    const signatures = [2, 4, 8, 16, 32, 64, 128, 256]
    let delClicks = 0
    const user = JSON.parse(localStorage.getItem('authenticated'))

    const preventDiv = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            document.execCommand('insertHTML', false, `<br/>${zeroWidthSpace}`)
            return false
        }
    }

    const setup = async () => {
        if (tabRef.current && timeSignature.current) {
            const timeSig = timeSignature.current.innerText.split('/')
            settings.beatNote = timeSig[1]
            const result = await parseFile(midi)
            let html = ''
            result.forEach(track => {
                html += `\r\n${track.name}\r\n\r\n`
                // Intro bars
                track.bars.splice(0, settings.introBars).forEach((bar, index, arr) => {
                    html += bar.text
                    if ((index !== 0 && (index + 1) % settings.barsPerRow === 0) || index + 1 === arr.length)
                        html += '|\r\n'
                })
                // The rest
                track.bars.forEach((bar, index) => {
                    html += bar.text
                    if (index !== 0 && (index + 1) % settings.barsPerRow === 0)
                        html += '|\r\n'
                })
                html += '\r\n\r\n\r\n'
            })
            tabRef.current.innerHTML = html;
            tabUpdate()
        }
    }

    if (post.midi) {
        midi = URL.createObjectURL(post.midi)
        setTimeout(setup, 500)
    }

    useEffect(() => {
        const updateTab = () => {
            const zeroReg = new RegExp(`\\${zeroWidthSpace}`, "ig")
            let html = tabRef.current.innerText.replace(zeroReg, '')
            const symbols = [
                { regex: '(\\|)', colour: 'lightgreen' },
                { regex: '(\\[.*?\\])', colour: '#42c3ff' },
                { regex: '(\\(.*?\\))', colour: '#ff5c5c' }
            ]

            symbols.forEach((symbol) => {
                const regEx = new RegExp(`${symbol.regex}`, "ig")
                html = html.replace(regEx, function (match) {
                    return `<span style="color:${symbol.colour};">${match}</span>`
                })
            })
            tabRef.current.innerHTML = html
        }
        if (songId) {
            tabRef.current.innerHTML = post.formatting.tab
            updateTab()
        }

        title.current.innerHTML = post.name
        tempo.current.innerHTML = post.formatting.tempo
        timeSignature.current.innerHTML = `${post.formatting.timeSignature.beats}/${post.formatting.timeSignature.base}`
        author.current.innerHTML = post.author
    }, [post, songId, tabRef, tempo, title, timeSignature, author])

    const controlsUpdate = () => {
        // Tempo
        const t = tempo.current.innerText
        if (isNaN(t) || t.length === 0)
            tempo.current.innerHTML = '120'

        // Song Name
        const name = title.current.innerText
        if (name.length > 50)
            title.current.innerHTML = name.substring(0, 40)
        else if (!name || name.length === 0)
            title.current.innerHTML = post.name

        // Song Name
        const publisher = author.current.innerText
        if (publisher.length > 20)
            author.current.innerHTML = publisher.substring(0, 20)
        else if (!publisher || publisher.length === 0)
            author.current.innerHTML = post.author

        // Time Signature
        const sig = timeSignature.current.innerText
        if (!sig || sig.length < 3 || sig.indexOf('/') === -1)
            timeSignature.current.innerHTML = '4/4'
        else {
            const parts = sig.split('/')
            if (parts.length > 2) {
                timeSignature.current.innerHTML = '4/4'
            }
            else {
                parts[0] = parts[0].trim()
                parts[1] = parts[1].trim()
                if (parts[0].length === 0 || parts[1].length === 0 || isNaN(parts[0]) || isNaN(parts[1])) {
                    timeSignature.current.innerHTML = '4/4'
                }
                else if (signatures.indexOf(parts[1]) === -1) {
                    parts[1] = parseInt(parts[1], 10)
                    const closestSignature = signatures.map(signature => { return { num: Math.abs(signature - parts[1]), sig: signature } })
                    closestSignature.sort((a, b) => { return a.num - b.num })
                    timeSignature.current.innerHTML = `${parts[0]}/${closestSignature[0].sig}`
                    setup()
                }
            }
        }
    }

    const preventNewline = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            return false
        }
    }

    const preventMore = (e) => {
        if (e.keyCode !== 8 && e.target.innerText.length >= 40 && !e.ctrlKey) {
            e.preventDefault()
        }
        preventNewline(e)
    }

    const preventSome = (e) => {
        if (e.keyCode !== 8 && e.target.innerText.length >= 20 && !e.ctrlKey) {
            e.preventDefault()
        }
        preventNewline(e)
    }

    const postSong = async (e) => {
        if (isPosting === false) {

            const tab = tabRef.current.innerText
            const name = title.current.innerText
            const publisher = author.current.innerText

            if (!songId && (tab === post.formatting.tab || !tab || tab.length === 0 || name === post.name || publisher === post.author || !midi)) {
                return
            }
            else if (songId && tab === post.formatting.tab && name === post.name && publisher === post.author && !mp3 && !midi) {
                return
            }

            isPosting = true
            const timeSig = timeSignature.current.innerText.split('/')
            const song = JSON.parse(JSON.stringify(post))
            Object.assign(song, {
                name: title.current.innerText,
                author: author.current.innerText,
                formatting: {
                    tab: tabRef.current.innerText,
                    timeSignature: {
                        beats: parseInt(timeSig[0]),
                        base: parseInt(timeSig[1])
                    },
                    tempo: parseInt(tempo.current.innerText)
                },
                isMidi: false,
                hasMp3: mp3 ? true : post.hasMp3,
                midi: undefined,
                lastModified: new Date().getTime()
            })

            e.target.innerHTML = 'Loading..'

            if (songId) {
                const editSong = firebase.app.functions('europe-west1').httpsCallable('editsongv2')
                song.id = songId
                const success = await editSong(song).catch((err) => { console.log(err); return false })
                if (success && success.data) {
                    if (mp3) {
                        const upload = firebase.app.storage().ref(`songs/${songId}/${user.uid}/mp3.mp3`).put(mp3)
                        upload.on('state_changed', (e) => { console.log('Upload change') }, (err) => {
                            console.log(err)
                        }, () => {
                            console.log('Uploaded Mp3')
                            isPosting = false
                            setTimeout(() => {
                                history.push(`/song/${songId}`)
                            }, 1000)
                        })
                    } else {
                        isPosting = false
                        history.push(`/song/${songId}`)
                    }
                }
                isPosting = false
                e.target.innerHTML = 'Save'
            }
            else {
                const addSong = firebase.app.functions('europe-west1').httpsCallable('addsongv2')
                const success = await addSong(song).catch((err) => { console.log(err); return false })
                if (success && success.data) {
                    if (mp3) {
                        const upload = firebase.app.storage().ref(`songs/${success.data}/${user.uid}/mp3.mp3`).put(mp3)
                        upload.on('state_changed', (e) => { console.log('Upload change') }, (err) => {
                            console.log(err)
                        }, () => {
                            console.log('Uploaded Mp3')
                            isPosting = false
                            setTimeout(() => {
                                history.push(`/song/${success.data}`)
                            }, 1000)
                        })
                    } else {
                        isPosting = false
                        history.push(`/song/${success.data}`)
                    }
                }
                isPosting = false
                e.target.innerHTML = 'Post Song'
            }
        }
    }

    const deleteSong = async () => {
        switch (delClicks) {
            case -1:
                deleteButton.current.innerHTML = 'Delete'
                break
            case 0:
                deleteButton.current.innerHTML = 'You sure?'
                break
            case 1:
                deleteButton.current.innerHTML = 'Last chance'
                break
            case 2:
                deleteButton.current.innerHTML = 'Deleting..'
                const deleteSong = firebase.app.functions('europe-west1').httpsCallable('deletesongv2')
                const del = { id: songId }
                await deleteSong(del).then(() => {
                    history.push(`/songbook`)
                }).catch(() => {
                    deleteButton.current.innerHTML = 'Failed'
                })
                break
            default:
        }
        delClicks += 1
    }

    const setMp3File = async (e) => {
        const { files } = e.target
        if (files && files.length !== 0) {
            const size = files[0].size / 1000000
            if (size < 8) {
                mp3 = files[0]
                e.target.parentNode.style.border = '1px darkgreen solid'
            }
        }
    }

    const setMidiFile = async (e) => {
        // redirect to midi tabbing
        const { files } = e.target
        if (files && files.length !== 0) {
            midi = URL.createObjectURL(files[0])
            setup()
        }
    }

    const handlePaste = (e) => {
        e.preventDefault()
        const text = e.clipboardData.getData('text/plain')
        document.execCommand('insertText', false, text)
    }

    const tabUpdate = () => {
        const zeroReg = new RegExp(`\\${zeroWidthSpace}`, "ig")
        let html = tabRef.current.innerText.replace(zeroReg, '')
        const symbols = [
            { regex: '(\\|)', colour: 'lightgreen' },
            { regex: '(\\[.*?\\])', colour: '#42c3ff' },
            { regex: '(\\(.*?\\))', colour: '#ff5c5c' }
        ]

        symbols.forEach((symbol) => {
            const regEx = new RegExp(`${symbol.regex}`, "ig")
            html = html.replace(regEx, function (match) {
                return `<span style="color:${symbol.colour};">${match}</span>`
            })
        })
        tabRef.current.innerHTML = html
    }

    return (
        <StyledMidiPost>
            <div className="fillTop"></div>
            <div className="controls">
                <div className="text">
                    <span className="key">Name:</span><span ref={title} className="value" contentEditable onKeyDown={preventMore} onBlur={controlsUpdate}></span>
                </div>
                <br /><br />
                <div className="text">
                    <span className="key">Author:</span><span ref={author} className="value" contentEditable onKeyDown={preventSome} onBlur={controlsUpdate}></span>
                </div>
                <div className="text">
                    <span className="key">Tempo:</span><span ref={tempo} className="value" contentEditable onKeyDown={preventSome} onBlur={controlsUpdate}></span>
                </div>
                <div className="text">
                    <span className="key">Time Signature:</span><span ref={timeSignature} className="value" onKeyDown={preventSome} contentEditable onBlur={controlsUpdate}></span>
                </div>
                <br /><br />
                <div className="text"><span className="key">Intro Bars:</span><input type="number" min="0" max="100" className="introBars" defaultValue="0" onChange={(e) => {
                    settings.introBars = e.target.valueAsNumber
                    setup()
                }} />
                </div>

                <div className="text"><span className="key">BPR:</span><input type="number" min="0" max="100" className="barsPerRow" defaultValue="4" onChange={(e) => {
                    settings.barsPerRow = e.target.valueAsNumber
                    setup()
                }} />
                </div>

                <div className="text"><span className="key">Offset:</span><input type="number" min="-5" max="5" className="octave" defaultValue="0" onChange={(e) => {
                    settings.octaveOffset = e.target.valueAsNumber
                    setup()
                }} />
                </div>

                <div className="text"><span className="key">Transpose:</span><input type="number" min="-30" max="30" className="barsPerRow" defaultValue="0" onChange={(e) => {
                    settings.steps = e.target.valueAsNumber
                    setup()
                }} />
                </div>

                <div className="button midiDrop" >Midi<input ref={midiFileRef} type="file" className="input" accept=".midi,.mid" onChange={setMidiFile} /></div>
                <div className="button mp3Drop" >Mp3<input ref={mp3File} type="file" className="input" accept=".mp3" onChange={setMp3File} /></div>
                {
                    songId ? (<div ref={deleteButton} className="button delete" onPointerLeave={() => { delClicks = -1; deleteSong() }} onClick={deleteSong}>Delete</div>) : ''
                }
                <div className={songId ? 'button save' : 'button create'} onClick={postSong} >{songId ? 'Save' : 'Post Song'}</div>
            </div>
            <pre ref={tabRef} tabIndex={0} onPaste={handlePaste} onBlur={tabUpdate} onKeyDown={preventDiv} className="tab" contentEditable></pre>
            <div className="fill"></div>
        </StyledMidiPost>
    )
}

const timeSigUnits = (timesignature, ppq) => {
    let barUnit = ppq;
    let ticksPerBar = ppq * 4;
    switch (timesignature[1]) {
        case 1: barUnit = ppq * 4; break;
        case 2: barUnit = ppq * 2; break;
        case 4: barUnit = ppq; break;
        case 8: barUnit = ppq / 2; break;
        case 16: barUnit = ppq / 4; break;
        case 32: barUnit = ppq / 8; break;
        default: break;
    }

    ticksPerBar = barUnit * timesignature[0];

    if (settings.beatNote !== 'time') {
        switch (settings.beatNote) {
            case '2': barUnit = ppq * 2; break;
            case '4': barUnit = ppq; break;
            case '8': barUnit = ppq / 2; break;
            case '16': barUnit = ppq / 4; break;
            case '32': barUnit = ppq / 8; break;
            case '64': barUnit = ppq / 16; break;
            case '128': barUnit = ppq / 32; break;
            case '256': barUnit = ppq / 64; break;
            default: break;
        }
    }

    return { unit: barUnit, perBar: ticksPerBar };
}

const nextTimeSig = (timesignatures, index, currTick) => {
    return (timesignatures[index].ticks <= currTick);
}

const nextTempo = (tempos, index, currTick) => {
    return (tempos[index].ticks <= currTick);
}

const getOctaveBrackets = (octave, min, max) => {
    let middleOctave = min + Math.floor((max - min) / 2);
    middleOctave += settings.octaveOffset;
    if (octave > middleOctave + 1) return { start: '<', end: '>', high: true };
    if (octave < middleOctave - 1) return { start: '{', end: '}', low: true };
    if (octave === middleOctave - 1) return { start: '[', end: ']' };
    if (octave === middleOctave + 1) return { start: '(', end: ')' };
    return { start: '', end: '' };
}

const ticksToPause = (ticks, quarter, isNote) => {
    let sixteenth = quarter / 4;
    const notes = [
        { ticks: (sixteenth * 7), pause: '.  -' }, // Double dotted quarter note
        { ticks: (sixteenth * 6), pause: '  -' }, // Dotted quarter note
        { ticks: (sixteenth * 5), pause: '.  ' }, // Quarter note + 16th note
        { ticks: (sixteenth * 4), pause: '  ' }, // Quarter note
        { ticks: (sixteenth * 3.5), pause: '\u00B7. ' }, // Double dotted 8th note
        { ticks: (sixteenth * 3), pause: '\u00B7 ', notNote: '\u00B7' }, // Dotted 8th note
        { ticks: (sixteenth * 2.5), pause: '. ' }, // 8th note + 32nd note
        { ticks: (sixteenth * 2), pause: ' ' }, // 8th note
        { ticks: (sixteenth * 1.75), pause: '\u00B7.' }, // Double dotted 16th note
        { ticks: (sixteenth * 1.5), pause: '\u00B7' }, // Dotted 16th note
        { ticks: (sixteenth * 1.25), pause: '.' }, // 16th note + 64th note
        { ticks: (sixteenth), pause: '', notNote: '\u00B7' }, // 16th note
        { ticks: (sixteenth * 0.5), pause: '' }, // 32nd note
        { ticks: (sixteenth * 0.25), pause: '' }, // 64th note
        { ticks: (sixteenth * 0.125), pause: '' } // 128th note
    ];

    const closest = notes.reduce((resp, note) => {
        if (ticks > notes[0].ticks) return false;
        if (resp === false) resp = note;
        let respVal = Math.abs(resp.ticks - ticks);
        let noteVal = Math.abs(note.ticks - ticks);
        if (respVal === 0) return resp;
        if (noteVal <= respVal) return note;
        return resp;
    }, false);
    let ret = closest.pause;
    if (closest.notNote && !isNote) ret = closest.notNote;
    return { ticks: closest.ticks, ret: ret };
}

const addPauseTicks = (gw2, ticks, unit, note, first) => {
    if (first && !note) {
        gw2 += (ticks / unit >= 1) ? ' ' : ''
        while ((ticks / unit) >= 1) {
            gw2 += '\u2501 ';
            ticks -= unit;
        }
        switch (ticks) {
            case unit / 1.5: gw2 += '-. '; ticks = 0; break;
            case unit / 2: gw2 += '- '; ticks = 0; break;
            case unit / 4: gw2 += '. '; ticks = 0; break;
            default: break;
        }
    }

    if (ticks <= 0) return gw2;

    let addOn = '';
    while ((ticks / unit) > 1.5) { addOn += '\u2501 '; ticks -= unit; }
    let pause = ticksToPause(ticks, unit, note);

    if (pause) {
        let append = false;
        append = pause.ret[pause.ret.length - 1] !== ' ' ? pause.ret + '' + addOn : pause.ret + addOn + ''
        append = append.replace('  -\u2501', ' \u2501 \u2501');
        gw2 += append;
    }
    else
        gw2 += '' + addOn + '';

    return gw2;
}


const transposeNote = (note) => {
    const notes = { 1: 1, '1#': 2, 2: 3, '2#': 4, 3: 5, 4: 6, '4#': 7, 5: 8, '5#': 9, 6: 10, '6#': 11, 7: 12 }
    let nVal = notes[note] + settings.steps
    let octaveDiff = 0
    while (nVal > 12) { nVal -= 12; octaveDiff++; }
    while (nVal < 1) { nVal += 12; octaveDiff--; }
    let key = Object.keys(notes).find(key => notes[key] === nVal)
    return { note: key, octaveDiff: octaveDiff }
}


const parseFile = async (midi) => {
    midi = await Midi.fromUrl(midi)
    const ppq = midi.header.ppq;
    const quarter = ppq;
    const multipleSignatures = midi.header.timeSignatures.length > 1;
    let gw2 = '';
    // const timeSignatures = midi.header.timeSignatures;
    const tempos = midi.header.tempos;

    const keyToNum = { C: 1, D: 2, E: 3, F: 4, G: 5, A: 6, B: 7 };

    midi.tracks = midi.tracks.reduce((sum, val) => {
        if (val.notes && val.notes.length > 0)
            sum.push(val);
        return sum;
    }, []);

    const tracks = midi.tracks.map(track => {
        let currTimeSig = midi.header.timeSignatures[0].timeSignature;
        let timeSigIndex = 1;
        let tempoIndex = 1;
        let units = timeSigUnits(currTimeSig, ppq);
        let ticksPerBar = units.perBar;
        let barUnit = units.unit;

        let currTick = 0;
        let bars = [{ notes: [], tick: currTick, ticks: ticksPerBar, unit: barUnit, timeSignatureChange: [currTimeSig] }];
        const notes = track.notes;

        let minOctave = 1337;
        let maxOctave = -1337;

        notes.forEach(n => { n.isSharp = n.pitch.indexOf('#') !== -1; n.pitch = n.pitch.substr(0, 1); })

        notes.sort((a, b) => {
            if (a.ticks - b.ticks !== 0) return a.ticks - b.ticks;
            if (a.octave - b.octave !== 0) return a.octave - b.octave;
            return keyToNum[a.pitch] - keyToNum[b.pitch];
        });

        let currentOctave = -1;
        let prevTick = 0;

        // Setup notes
        notes.forEach((note, index) => {
            if (index === 0) {
                currentOctave = note.octave;
                prevTick = note.ticks;
            }
            currentOctave = note.octave;
            prevTick = note.ticks;
            if (tempos[tempoIndex] && nextTempo(tempos, tempoIndex, prevTick)) {
                note.tempo = Math.floor(tempos[tempoIndex].bpm);
                tempoIndex++;
                while (tempos[tempoIndex] && nextTempo(tempos, tempoIndex, prevTick)) {
                    note.tempoChange = Math.floor(tempos[tempoIndex].bpm);
                    tempoIndex++;
                }
            }
            while (currTick <= note.ticks) {
                currTick += ticksPerBar;
                if (multipleSignatures && midi.header.timeSignatures[timeSigIndex] && nextTimeSig(midi.header.timeSignatures, timeSigIndex, currTick)) {
                    units = timeSigUnits(midi.header.timeSignatures[timeSigIndex].timeSignature, ppq);
                    currTimeSig = midi.header.timeSignatures[timeSigIndex];
                    timeSigIndex++;
                    ticksPerBar = units.perBar;
                    barUnit = units.unit;
                    bars.push({ notes: [], tick: currTick, ticks: ticksPerBar, unit: barUnit, timeSignature: currTimeSig });
                }
                else
                    bars.push({ notes: [], tick: currTick, ticks: ticksPerBar, unit: barUnit });
            }
            const barIndex = bars.findIndex(x => note.ticks >= x.tick && note.ticks < (x.tick + x.ticks));
            bars[barIndex].notes.push(note);

            if (note.octave > maxOctave) maxOctave = note.octave;
            if (note.octave < minOctave) minOctave = note.octave;
        })
        // -----------------------------------------------------------------------------

        currentOctave = -1;
        let previousOctave = -1;
        let brackets = { start: '', end: '' };
        let count = 1;

        bars = bars.map((bar, bIndex) => {
            gw2 = '|';
            if (bar.notes.length > 0) {
                let pauseTicks = bar.notes[0].ticks - bar.tick;
                if (pauseTicks > 0) // There is a pause at the start of the bar
                    gw2 = addPauseTicks(gw2, pauseTicks, bar.unit, false, true);
                prevTick = 0;
                bar.notes.forEach((note, index) => {
                    if (note.tempoChange && note.tempoChange !== '') {
                        gw2 += note.tempoChange;
                    }

                    let key = keyToNum[note.pitch] + (note.isSharp ? '#' : '');
                    let nTransposed = transposeNote(key)
                    note.octave = note.octave + nTransposed.octaveDiff
                    key = nTransposed.note

                    if (!isNaN(key))
                        key = parseInt(key)
                    const nextNote = bar.notes[index + 1];
                    let startOffset = 0;
                    if (currentOctave === -1)
                        currentOctave = note.octave;
                    if (index === 0) { // First note in the bar
                        prevTick = note.ticks;
                        if (key === 1 && currentOctave < note.octave) {
                            key = 8;
                            startOffset = -1;
                        }
                        let noteOctave = note.octave + startOffset;
                        brackets = getOctaveBrackets(noteOctave, minOctave, maxOctave);
                        if (key === 1 && brackets.high) {
                            key = 8;
                            startOffset = -1;
                            brackets = getOctaveBrackets(noteOctave, minOctave, maxOctave);
                        }
                        noteOctave = note.octave + startOffset;
                        currentOctave = noteOctave;
                        gw2 += brackets.start;
                        gw2 += key;
                    }
                    else // Not first note in bar
                    {
                        let starterBracket = false;
                        let checkBrack = getOctaveBrackets(note.octave, minOctave, maxOctave);
                        if (previousOctave !== note.octave && key !== 1) { // Octave has changed
                            gw2 += brackets.end;
                            currentOctave = note.octave;
                            brackets = getOctaveBrackets(currentOctave, minOctave, maxOctave);
                            starterBracket = true;
                        } else if (previousOctave !== note.octave) {
                            if (previousOctave === note.octave - 1) {

                            }
                            // A 1 but it is not a chord
                            if (previousOctave < note.octave && (!nextNote || (nextNote && nextNote.ticks !== note.ticks))) {
                                key = 8;
                                if (previousOctave !== note.octave - 1) {
                                    gw2 += brackets.end;
                                    starterBracket = true;
                                }
                                currentOctave = note.octave - 1;
                                brackets = getOctaveBrackets(currentOctave, minOctave, maxOctave);
                            } else { // A 1 in a chord
                                gw2 += brackets.end;
                                currentOctave = note.octave;
                                brackets = getOctaveBrackets(currentOctave, minOctave, maxOctave);
                                starterBracket = true;
                            }
                        } else if ((brackets.high || checkBrack.high) && key === 1) {
                            gw2 += brackets.end;
                            key = 8;
                            currentOctave = note.octave - 1;
                            brackets = getOctaveBrackets(currentOctave, minOctave, maxOctave);
                        }
                        else if (key === 1 && nextNote && nextNote.octave < note.octave && nextNote.ticks !== note.ticks && (nextNote.ticks - note.ticks) < quarter) {
                            gw2 += brackets.end;
                            key = 8;
                            currentOctave = note.octave - 1;
                            brackets = getOctaveBrackets(currentOctave, minOctave, maxOctave);
                        }

                        if (previousOctave !== currentOctave)
                            starterBracket = true;

                        pauseTicks = note.ticks - prevTick;
                        // Pause or chord
                        gw2 = (pauseTicks > 0 ? addPauseTicks(gw2, pauseTicks, bar.unit, true) : gw2 + '/');
                        gw2 += (starterBracket ? brackets.start : '');
                        gw2 += key;
                        prevTick = note.ticks;
                    }

                    if (index === bar.notes.length - 1) {
                        gw2 += brackets.end;
                        pauseTicks = (bar.tick + bar.ticks) - note.ticks;
                        if (pauseTicks > 0)
                            gw2 = addPauseTicks(gw2, pauseTicks, bar.unit, false);
                    }
                    previousOctave = currentOctave;
                });
            } // No notes
            else { gw2 = addPauseTicks(gw2, bar.ticks, bar.unit, false, true); }

            let trueIndex = bIndex - settings.barSkip;
            if (trueIndex > -1) {
                if (count === settings.barsPerRow) {
                    gw2 += '|';
                    count = 0;
                }
                count++;
            } else if (trueIndex === -1)
                gw2 += '|';

            bar.text = gw2
            return bar
        });

        // ------------------------------------------------------------------------

        return {
            name: track.instrument.name,
            bars: bars
        };
    })

    return tracks
}

export default MidiPost