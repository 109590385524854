import React from 'react'
import { StyledVisualizer } from './visualizer.styled'
import { MidiVisualization } from '../../components/'

const Visualizer = () => {

    return (
        <StyledVisualizer>
            <MidiVisualization />
        </StyledVisualizer>
    )
}

export default Visualizer