import React, { useEffect, useState } from 'react'
import './songbook.css'
import { Menu, Burger } from './components'
import { About, Favourites, Post, FAQ, List, Viewer, Drafts, Visualizer } from './pages'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Firebase from './firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const Songbook = () => {
  const firebase = new Firebase()
  const auth = firebase.app.auth()
  const [open, setOpen] = useState(false)
  const [user] = useAuthState(auth)
  const [anon, setAnon] = useState(false)

  // Store user in cache
  useEffect(() => {
    if (user) {
      localStorage.setItem('authenticated', JSON.stringify(user))
      localStorage.removeItem('anon')
      setAnon(false)
    } else {
      localStorage.setItem('anon', 1)
      setAnon(true)
    }
  }, [user])


  const popUp = () => {
    const provider = new firebase.class.auth.GoogleAuthProvider()
    auth.signInWithPopup(provider)
  }

  const SignIn = () => {
    const anonSignIn = () => {
      localStorage.setItem('anon', 1)
      setAnon(true)
    }

    const styles = {
      position: 'absolute',
      backgroundColor: 'rgb(30, 30, 30)',
      width: '100%',
      height: '100%'
    }

    const btnStyles = {
      position: 'absolute',
      top: '50%',
      left: 'calc(50% - 250px)',
      transform: 'translateX(-50%) translateY(-50%)',
      height: '150px',
      width: '400px',
      textAlign: 'center',
      lineHeight: '150px',
      backgroundColor: 'rgb(50, 50, 50)',
      color: 'rgb(200, 200, 200)',
      fontSize: '35px',
      border: '2px rgb(100, 100, 100) solid',
      userSelect: 'none'
    }

    const btnStyles2 = {
      position: 'absolute',
      top: '50%',
      left: 'calc(50% + 250px)',
      transform: 'translateX(-50%) translateY(-50%)',
      height: '150px',
      width: '400px',
      textAlign: 'center',
      lineHeight: '150px',
      backgroundColor: 'rgb(50, 50, 50)',
      color: 'rgb(200, 200, 200)',
      fontSize: '35px',
      border: '2px rgb(100, 100, 100) solid',
      userSelect: 'none'
    }

    const hover = (e) => {
      e.target.style.backgroundColor = 'rgb(40, 40, 40)'
    }

    const active = (e) => {
      e.target.style.backgroundColor = 'rgb(20, 20, 20)'
    }

    const inactive = (e) => {
      e.target.style.backgroundColor = 'rgb(40, 40, 40)'
    }

    const leave = (e) => {
      e.target.style.backgroundColor = 'rgb(50, 50, 50)'
    }

    return (
      <div className="background" style={styles}>
        <div className="signIn" onPointerEnter={hover} onPointerLeave={leave} onMouseDown={active} onMouseUp={inactive} style={btnStyles} onClick={popUp}>Sign in with Google</div>
        <div className="anon" onPointerEnter={hover} onPointerLeave={leave} onMouseDown={active} onMouseUp={inactive} style={btnStyles2} onClick={anonSignIn}>Nah, just gimme tabs.</div>
      </div>
    )
  }

  return (
    <Router>
      {
        (user || localStorage.getItem('authenticated') || anon) ? (
          <div className="songbook">
            <div className="burgerMenuContainer">
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} signIn={popUp} />
            </div>
            <Route exact path="/" component={List} />
            <Route path="/favourites" component={Favourites} />
            <Route path="/songbook" component={List} />
            <Route path="/drafts" component={Drafts} />
            <Route path="/post/:songId?" component={Post} />
            <Route path="/faq" component={FAQ} />
            <Route path="/about" component={About} />
            <Route path="/song/:songId" component={Viewer} />
            <Route path="/visualizer" component={Visualizer} />
          </div>
        ) : <SignIn />
      }
    </Router>
  )
}

export default Songbook