import styled from 'styled-components'

export const StyledBurger = styled.button`
  position: fixed;
  top: 50px;
  left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  span {
    width: 50px;
    height: 5px;
    background: rgb(230, 230, 230);
    border-radius: 10px;
    transition: 0.2s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      transform-origin: center center;
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'rotate(-180deg)' : 'rotate(0deg)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;