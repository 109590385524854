import styled from 'styled-components'

const padding = 20

export const StyledSongdetails = styled.div`
  position: relative;
  float:left;
  min-width: 600px;
  width: 75%;
  margin-left: calc(12.5% - 19px);
  height: 75px;
  text-align: left;
  background-color: rgb(50, 50, 50);
  padding: 0 ${padding}px;

  &.pinned {
    position: fixed;
    top: 0px;
    border: 1px solid black;
    margin-left: calc(12.5% - 20px);
  }

  .songName {
    font-size:30px;
    display: inline-block;
    width: 100%;
    
    .author {
      margin-top: 5px;
      float: right;
      font-style: italic;
      font-size: 20px;
    }
  }

  .songInfo {
    position:absolute;
    display: inline-block;
    width:calc(100% - ${padding * 2}px);
    bottom: ${padding / 2}px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    
    div {
      margin-right: 20px;
    }

    .instruments {
      position: absolute;
      right: 0;
      margin-right:0px;
      font-style: italic;
    }
  }

  .mp3 {
    position:absolute;
    right: 230px;
    bottom:5px;
    height:30px;
    width:300px;
    border-radius: 20px;
    filter: sepia(20%) saturate(70%) grayscale(1) contrast(100%) invert(70%);

    &:focus {
      outline: none;
    }
  }

  .button {
    position:absolute;
    right: 15px;
    bottom: 5px;
    height:20px;
    line-height:20px;
    padding: 5px 10px;
    text-align:center;
    border:1px black solid;
    background-color: rgb(60, 60, 60);

    &:hover {
      cursor: pointer;
      background-color: rgb(40, 40, 40);
    }

    &:active:hover {
      background-color: rgb(20, 20, 20);
    }
  }

  .PowerPoints {
    position:absolute;
    bottom: 5px;
    right: 115px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    border: 1px solid black;
    font-weight: bold;
    user-select: none;

    background-color: rgb(60, 60, 60);

    &:hover {
      cursor: pointer;
      background-color: rgb(40, 40, 40);
    }

    &:active:hover {
      background-color: rgb(20, 20, 20);
    }
  }
  
  .PowerPoints.liked {
    border: 1px solid green;
  }

  .pin {
    position:absolute;
    bottom: 5px;
    right: 168px;
    padding: 0 7px;
    height: 30px;
    line-height: 30px;
    border: 1px solid black;
    font-weight: bold;
    user-select: none;

    background-color: rgb(60, 60, 60);

    &:hover {
      cursor: pointer;
      background-color: rgb(40, 40, 40);
    }

    &:active:hover {
      background-color: rgb(20, 20, 20);
    }
  }
  
  .pin.isPinned {
    border: 1px solid green;
  }
`;