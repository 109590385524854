import React, { useEffect, createRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { StyledRegularPost } from './regularpost.styled.js'
import Firebase from '../../firebase'

const RegularPost = ({ post, setPost }) => {
    const firebase = new Firebase()
    const history = useHistory()
    const { songId } = useParams()
    const tabRef = createRef()
    const title = createRef()
    const author = createRef()
    const tempo = createRef()
    const timeSignature = createRef()
    const deleteButton = createRef()
    const midiFile = createRef()
    const mp3File = createRef()
    const togglePublic = createRef()
    const zeroWidthSpace = '​'
    const signatures = [2, 4, 8, 16, 32, 64, 128, 256]

    const tags = ['solo', 'duet', 'band', 'easy', 'medium', 'hard', 'expert']
    const songTags = post.tags ? JSON.parse(JSON.stringify(post.tags)) : {}

    let mp3 = undefined
    let midi = undefined
    let isPosting = false
    let isPublic = post.public || false
    let delClicks = 0
    const user = JSON.parse(localStorage.getItem('authenticated'))

    const controlsUpdate = () => {
        // Tempo
        const t = tempo.current.innerText
        if (isNaN(t) || t.length === 0)
            tempo.current.innerHTML = '120'

        // Song Name
        const name = title.current.innerText
        if (name.length > 50)
            title.current.innerHTML = name.substring(0, 40)
        else if (!name || name.length === 0)
            title.current.innerHTML = post.name

        // Song Name
        const publisher = author.current.innerText
        if (publisher.length > 20)
            author.current.innerHTML = publisher.substring(0, 20)
        else if (!publisher || publisher.length === 0)
            author.current.innerHTML = post.author

        // Time Signature
        const sig = timeSignature.current.innerText
        if (!sig || sig.length < 3 || sig.indexOf('/') === -1)
            timeSignature.current.innerHTML = '4/4'
        else {
            const parts = sig.split('/')
            if (parts.length > 2) {
                timeSignature.current.innerHTML = '4/4'
            }
            else {
                parts[0] = parts[0].trim()
                parts[1] = parts[1].trim()
                if (parts[0].length === 0 || parts[1].length === 0 || isNaN(parts[0]) || isNaN(parts[1])) {
                    timeSignature.current.innerHTML = '4/4'
                }
                else if (signatures.indexOf(parts[1]) === -1) {
                    parts[1] = parseInt(parts[1], 10)
                    const closestSignature = signatures.map(signature => { return { num: Math.abs(signature - parts[1]), sig: signature } })
                    closestSignature.sort((a, b) => { return a.num - b.num })
                    timeSignature.current.innerHTML = `${parts[0]}/${closestSignature[0].sig}`
                }
            }
        }
    }

    const tabUpdate = () => {
        const zeroReg = new RegExp(`\\${zeroWidthSpace}`, "ig")
        let html = tabRef.current.innerText.replace(zeroReg, '')
        const symbols = [
            { regex: '(\\|)', colour: 'lightgreen' },
            { regex: '(\\[.*?\\])', colour: '#42c3ff' },
            { regex: '(\\(.*?\\))', colour: '#ff5c5c' }
        ]

        symbols.forEach((symbol) => {
            const regEx = new RegExp(`${symbol.regex}`, "ig")
            html = html.replace(regEx, function (match) {
                return `<span style="color:${symbol.colour};">${match}</span>`
            })
        })
        tabRef.current.innerHTML = html
    }

    const preventNewline = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            return false
        }
    }

    const preventDiv = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            document.execCommand('insertHTML', false, `<br/>${zeroWidthSpace}`)
            return false
        }
    }

    const preventMore = (e) => {
        if (e.keyCode !== 8 && e.target.innerText.length >= 40 && !e.ctrlKey) {
            e.preventDefault()
        }
        preventNewline(e)
    }

    const preventSome = (e) => {
        if (e.keyCode !== 8 && e.target.innerText.length >= 20 && !e.ctrlKey) {
            e.preventDefault()
        }
        preventNewline(e)
    }

    useEffect(() => {
        const updateTab = () => {
            const zeroReg = new RegExp(`\\${zeroWidthSpace}`, "ig")
            let html = tabRef.current.innerText.replace(zeroReg, '')
            const symbols = [
                { regex: '(\\|)', colour: 'lightgreen' },
                { regex: '(\\[.*?\\])', colour: '#42c3ff' },
                { regex: '(\\(.*?\\))', colour: '#ff5c5c' }
            ]

            symbols.forEach((symbol) => {
                const regEx = new RegExp(`${symbol.regex}`, "ig")
                html = html.replace(regEx, function (match) {
                    return `<span style="color:${symbol.colour};">${match}</span>`
                })
            })
            tabRef.current.innerHTML = html
        }
        if (songId) {
            tabRef.current.innerHTML = post.formatting.tab
            togglePublic.current.innerHTML = post.public ? 'Unlist' : 'Publish'
            updateTab()
        }

        title.current.innerHTML = post.name
        tempo.current.innerHTML = post.formatting.tempo
        timeSignature.current.innerHTML = `${post.formatting.timeSignature.beats}/${post.formatting.timeSignature.base}`
        author.current.innerHTML = post.author
    }, [post, songId, tabRef, tempo, title, timeSignature, author, togglePublic])

    const postSong = async (e) => {
        if (isPosting === false) {

            const tab = tabRef.current.innerText
            const name = title.current.innerText
            const publisher = author.current.innerText

            if (!songId && (tab === post.formatting.tab || !tab || tab.length === 0 || name === post.name || publisher === post.author)) {
                return
            }
            else if (songId && tab === post.formatting.tab && name === post.name && publisher === post.author && !mp3 && !midi && isPublic === post.public && JSON.stringify(songTags) === JSON.stringify(post.tags)) {
                return
            }

            isPosting = true
            const timeSig = timeSignature.current.innerText.split('/')
            const song = JSON.parse(JSON.stringify(post))
            Object.assign(song, {
                name: title.current.innerText,
                author: author.current.innerText,
                formatting: {
                    tab: tabRef.current.innerText,
                    timeSignature: {
                        beats: parseInt(timeSig[0]),
                        base: parseInt(timeSig[1])
                    },
                    tempo: parseInt(tempo.current.innerText)
                },
                isMidi: false,
                hasMp3: mp3 ? true : post.hasMp3,
                lastModified: new Date().getTime(),
                public: isPublic,
                tags: songTags
            })

            e.target.innerHTML = 'Loading..'

            if (songId) {
                const editSong = firebase.app.functions('europe-west1').httpsCallable('editsongv2')
                song.id = songId
                const success = await editSong(song).catch((err) => { console.log(err); return false })
                if (success && success.data) {
                    if (mp3) {
                        const upload = firebase.app.storage().ref(`songs/${songId}/${user.uid}/mp3.mp3`).put(mp3)
                        upload.on('state_changed', (e) => { console.log('Upload change') }, (err) => {
                            console.log(err)
                        }, () => {
                            console.log('Uploaded Mp3')
                            isPosting = false
                            setTimeout(() => {
                                history.push(`/song/${songId}`)
                            }, 1000)
                        })
                    } else {
                        isPosting = false
                        history.push(`/song/${songId}`)
                    }
                }
                else {
                    isPosting = false
                    e.target.innerHTML = 'Save'
                }
            }
            else {
                const addSong = firebase.app.functions('europe-west1').httpsCallable('addsongv2')
                const success = await addSong(song).catch((err) => { console.log(err); return false })
                if (success && success.data) {
                    if (mp3) {
                        const upload = firebase.app.storage().ref(`songs/${success.data}/${user.uid}/mp3.mp3`).put(mp3)
                        upload.on('state_changed', (e) => { console.log('Upload change') }, (err) => {
                            console.log(err)
                        }, () => {
                            console.log('Uploaded Mp3')
                            isPosting = false
                            setTimeout(() => {
                                history.push(`/song/${success.data}`)
                            }, 1000)
                        })
                    } else {
                        isPosting = false
                        history.push(`/song/${success.data}`)
                    }
                }
                else {
                    isPosting = false
                    e.target.innerHTML = 'Post Song'
                }
            }
        }
    }

    const deleteSong = async () => {
        switch (delClicks) {
            case -1:
                deleteButton.current.innerHTML = 'Delete'
                break
            case 0:
                deleteButton.current.innerHTML = 'You sure?'
                break
            case 1:
                deleteButton.current.innerHTML = 'Last chance'
                break
            case 2:
                deleteButton.current.innerHTML = 'Deleting..'
                const deleteSong = firebase.app.functions('europe-west1').httpsCallable('deletesongv2')
                const del = { id: songId }
                await deleteSong(del).then(() => {
                    history.push(`/songbook`)
                }).catch(() => {
                    deleteButton.current.innerHTML = 'Failed'
                })
                break
            default:
        }
        delClicks += 1
    }

    const handlePaste = (e) => {
        e.preventDefault()
        const text = e.clipboardData.getData('text/plain')
        document.execCommand('insertText', false, text)
    }

    const setMp3File = async (e) => {
        const { files } = e.target
        if (files && files.length !== 0) {
            const size = files[0].size / 1000000
            if (size < 8) {
                mp3 = files[0]
                e.target.parentNode.style.border = '1px darkgreen solid'
            }
        }
    }

    const setMidiFile = async (e) => {
        // redirect to midi tabbing
        const { files } = e.target
        if (files && files.length !== 0) {
            const size = files[0].size / 1000000
            if (size < 5) {
                midi = files[0]
                const timeSig = timeSignature.current.innerText.split('/')
                const song = JSON.parse(JSON.stringify(post))
                Object.assign(song, {
                    name: title.current.innerText,
                    author: author.current.innerText,
                    formatting: {
                        tab: tabRef.current.innerText,
                        timeSignature: {
                            beats: parseInt(timeSig[0]),
                            base: parseInt(timeSig[1])
                        },
                        tempo: parseInt(tempo.current.innerText)
                    },
                    isMidi: true,
                    midi: midi,
                    lastModified: new Date().getTime()
                })
                setPost(song)
                e.target.parentNode.style.border = '1px darkgreen solid'
            }
        }
    }

    const publicToggle = async () => {
        isPublic = !isPublic
        togglePublic.current.innerHTML = isPublic ? 'Unlist' : 'Publish'
    }

    const toggleTag = async (e) => {
        const tag = e.target.innerText
        const hasTag = songTags[tag] ? true : false
        if (hasTag) {
            delete songTags[tag]
            e.target.style.borderColor = ''
        }
        else {
            songTags[tag] = tag
            e.target.style.borderColor = 'darkgreen'
        }
    }

    return (
        <StyledRegularPost>
            <div className="fillTop"></div>
            <div className="controls">
                <div className="text">
                    <span className="key">Name:</span><span ref={title} className="value" contentEditable onKeyDown={preventMore} onBlur={controlsUpdate}></span>
                </div><br /><br />
                <div className="text">
                    <span className="key">Author:</span><span ref={author} className="value" contentEditable onKeyDown={preventSome} onBlur={controlsUpdate}></span>
                </div>
                <div className="text">
                    <span className="key">Tempo:</span><span ref={tempo} className="value" contentEditable onKeyDown={preventSome} onBlur={controlsUpdate}></span>
                </div>
                <div className="text">
                    <span className="key">Time Signature:</span><span ref={timeSignature} className="value" onKeyDown={preventSome} contentEditable onBlur={controlsUpdate}></span>
                </div>
                {
                    songId ? (<div ref={togglePublic} className="button public" onClick={publicToggle}></div>) : ''
                }
                <div className="button midiDrop" >Midi<input ref={midiFile} type="file" className="input" accept=".midi,.mid" onChange={setMidiFile} /></div>
                <div className="button mp3Drop" >Mp3<input ref={mp3File} type="file" className="input" accept=".mp3" onChange={setMp3File} /></div>
                {
                    songId ? (<div ref={deleteButton} className="button delete" onPointerLeave={() => { delClicks = -1; deleteSong() }} onClick={deleteSong}>Delete</div>) : ''
                }
                <div className={songId ? 'button save' : 'button create'} onClick={postSong} >{songId ? 'Save' : 'Post Song'}</div>
            </div>
            <div className="tags">
                {
                    tags.map((tag, index) => {
                        const hasTag = songTags[tag] ? true : false
                        return (<div key={index} className="tag" style={{ borderColor: hasTag ? 'green' : '' }} onClick={toggleTag}>{tag}</div>)
                    })
                }
            </div>
            <pre ref={tabRef} tabIndex={0} onPaste={handlePaste} onBlur={tabUpdate} onKeyDown={preventDiv} className="tab" contentEditable></pre>
            <div className="fill"></div>
        </StyledRegularPost>
    )
}

export default RegularPost