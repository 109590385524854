import styled from 'styled-components'

export const StyledSonglist = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  background: rgb(50, 50, 50);
  width: 75%;
  min-width: 600px;
  padding: 20px 0;
  text-align: left;
  z-index: 0;
  background-color: rgb(40, 40, 40);

  .search {
    position:relative;
    height: 50px;
    width: calc(100% - 20px);
    margin: 10px;
    margin-top:-5px;
    margin-bottom:5px;
    border-radius:20px;
    background-color:rgb(150, 150, 150);

    .text {
      position:absolute;
      left:15px;
      width:calc(100% - 30px);
      height:40px;
      line-height:50px;
      outline: none;
      border-radius:20px;
      font-size:30px;
      color:rgb(20,20,20);

      &:empty:not(:focus):before {
        cursor: text;
        color:rgb(60,60,60);
        content: attr(placeholder);
        font-style:italic;
      }
      
      &:empty:before {
        cursor: text;
        color:rgb(60,60,60);
        content: attr(placeholder);
        font-style:italic;
      }
    }
  }

  .sortOptionSelect {
    position: absolute;
    right: 0px;
    height: 50px;
    width: 150px;
    font-size: 30px;
    text-align: center;
    background-color: rgb(150, 150, 150);
    color: rgb(20,20,20);
    outline: none;
    border-top-right-radius:20px;
    border-bottom-right-radius:20px;
  }

  .tags {
    position:relative;
    width:75%;
    min-width:600px;
    margin-left: 10px;
    padding: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow:hidden;
    color: rgb(210, 210, 210);

    .tag {
      background-color: rgb(50, 50, 50);
      display: inline-block;
      padding: 10px;
      margin-right: 10px;
      border: 1px solid black;
      
      &:hover {
        cursor: pointer;
        background-color:rgb(30, 30, 30);
      }
    }
  }
`;