import styled from 'styled-components'

export const StyledFAQ = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(50, 50, 50);
    width: 80vw;
    min-width: 600px;
    max-width: 800px;
    min-height: calc(100vh - 40px);
    padding: 20px 20px;
    text-align: left;
    z-index: 0;
    background-color: rgb(40, 40, 40);
    
    p {
        margin-bottom:50px;
    }
`;