import React, { useRef, useState } from 'react'
import { StyledMidiVisualization } from './midivisualizations.styled'
import { Midi } from '@tonejs/midi'
import * as jsmidgen from './jsmidgen';

const settings = {
    steps: 0,
    offset: 3,
    selectedTrack: 0,
    timers: [],
    speed: 1,
    overview: true,
    onlyMe: false,
    midi: false,
    sound: true,
    volume: 30
}

const createLink = (b) => {
    const bytes = new Uint8Array(b.length);
    for (let i = 0; i < b.length; i++) {
        bytes[i] = b.charCodeAt(i)
    }

    const blob = new Blob([bytes], { type: 'audio/midi' })

    return URL.createObjectURL(blob)
};


const MidiVisualization = () => {
    const [parsed, setParsed] = useState(undefined)
    const [tracks, setTracks] = useState([])
    const [url, setUrl] = useState('')
    const [orgininalUrl, setOriginalUrl] = useState('')

    const contentRef = useRef()
    const player = useRef()

    const trackChange = (e) => {
        settings.selectedTrack = parseInt(e.target.value, 10)
        setup(parsed, contentRef)
    }

    const volumeChange = (e) => {
        settings.volume = e.target.valueAsNumber

        if (!settings.settingVolume) {
            settings.settingVolume = true
            setTimeout(() => {
                midiSoundFile(orgininalUrl)
                settings.settingVolume = false
            }, 1000)
        }
    }

    const midiSoundFile = async (url) => {
        const midi = await Midi.fromUrl(url)
        const tracks = []
        const noteEvents = []
        midi.tracks.forEach(track => {
            const t = new jsmidgen.Track()

            let instrumentNumber = track.instrument.number
            if (instrumentNumber === 14 || instrumentNumber === 112)
                instrumentNumber = 1
            t.setInstrument(track.channel, instrumentNumber, 0)
            tracks.push(t)

            midi.header.tempos.forEach(tempo => {
                noteEvents.push({
                    bpm: tempo.bpm,
                    ticks: tempo.ticks,
                    type: 'tempo',
                    trackIndex: tracks.length - 1
                })
            })

            track.notes.forEach((note, i) => {
                noteEvents.push({
                    note: note,
                    type: 'on',
                    ticks: note.ticks,
                    trackIndex: tracks.length - 1
                })
            })

            track.notes.forEach((note, i) => {
                noteEvents.push({
                    note: note,
                    type: 'off',
                    ticks: note.ticks + note.durationTicks,
                    trackIndex: tracks.length - 1
                })
            })
        })


        noteEvents.sort((a, b) => { return a.ticks - b.ticks })

        tracks.forEach((track, j) => {
            let prevEvent = false
            for (let i = 0; i < noteEvents.length; i++) {
                if (j === noteEvents[i].trackIndex) {
                    const event = noteEvents[i]
                    const ticks = prevEvent ? (event.ticks - prevEvent.ticks) : 0
                    const muted = event.trackIndex !== j
                    const velocity = muted ? 0 : settings.volume
                    if (event.type === 'on') {
                        track.addNoteOn(midi.tracks[j].channel, event.note.midi, ticks, velocity)
                    }
                    else if (event.type === 'off') {
                        track.addNoteOff(midi.tracks[j].channel, event.note.midi, ticks, velocity)
                    }
                    else if (event.type === 'tempo') {
                        track.setTempo(event.bpm, ticks)
                    }
                    prevEvent = event
                }
            }
        })


        const file = new jsmidgen.File({
            ticks: midi.header.ppq,
            tracks: tracks
        })

        const bytes = file.toBytes()
        const newUrl = createLink(bytes)

        if (settings.midi && settings.playing) {
            const now = settings.midi.currentTime
            if (!settings.volumeTimeout) {
                settings.volumeTimeout = setTimeout(() => {
                    settings.midi.stop()
                    settings.midi.currentTime = now + 0.4
                    settings.midi.start()
                    settings.volumeTimeout = false
                }, 100)
            }
        }

        setUrl(newUrl)
    }

    const setMidiFile = async (e) => {
        // redirect to midi tabbing
        const { files } = e.target
        if (files && files.length !== 0) {
            const url = URL.createObjectURL(files[0])
            setOriginalUrl(url)
            await midiSoundFile(url)
            settings.midi = player.current
            const result = await parseFile(url)
            setParsed(result)

            const t = result.map((tracks, i) => {
                return {
                    name: tracks.name,
                    value: i
                }
            })

            setTracks(t)

            setup(result, contentRef)
        }
    }

    return (
        <StyledMidiVisualization>
            <div className="fillTop"></div>
            <div className="controls">
                <div className="text"><span className="key">Offset:</span><input type="number" min="-7" max="7" className="octave" defaultValue="3" onChange={(e) => {
                    settings.offset = e.target.valueAsNumber
                    setup(parsed, contentRef)
                }} />
                </div>

                <div className="text"><span className="key">Transpose:</span><input type="number" min="-12" max="12" className="barsPerRow" defaultValue="0" onChange={(e) => {
                    settings.steps = e.target.valueAsNumber
                    setup(parsed, contentRef)
                }} />
                </div>

                <div className="text"><span className="key">Speed:</span><input type="number" min="20" max="100" style={{ width: '50px' }} className="barsPerRow" defaultValue="100" onChange={(e) => {
                    settings.speed = 1 + (Math.abs(e.target.valueAsNumber - 100) / 100)
                }} />
                </div>

                <div className="text"><span className="key">Overview:</span><input type="checkbox" style={{ width: '20px' }} className="barsPerRow" defaultChecked="true" onChange={(e) => {
                    settings.overview = e.target.checked
                    setup(parsed, contentRef)
                }} />
                </div>

                <div className="text"><span className="key">Only Me:</span><input type="checkbox" style={{ width: '20px' }} className="barsPerRow" onChange={(e) => {
                    settings.onlyMe = e.target.checked
                    setup(parsed, contentRef)
                }} />
                </div>

                <div className="text"><span className="key">Sound:</span><input type="checkbox" style={{ width: '20px' }} className="barsPerRow" defaultChecked="true" onChange={(e) => {
                    settings.sound = e.target.checked
                    setup(parsed, contentRef)
                }} />
                </div>

                {
                    tracks && (
                        <select onChange={trackChange}>
                            {
                                tracks.map(t => {
                                    return (<option key={t.value} value={t.value}>{t.name}</option>)
                                })
                            }
                        </select>
                    )
                }
                <midi-player ref={player} style={{ position: 'absolute', width: '300px', height: '30px', bottom: '25px', left: '300px' }} volume="0.1" src={url} sound-font="https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus" ></midi-player>
                <div className="volume" style={{ position: 'absolute', width: '300px', height: '30px', bottom: '25px', left: '620px' }} ><span className="key">Volume:</span><input style={{ marginBottom: '-10px' }} type="range" min="0" max="100" defaultValue="30" onChange={volumeChange} /></div>
                <div className="button playback" onClick={() => play(parsed, contentRef)}>Play</div>
                <div className="button midiDrop" >Midi<input type="file" className="input" accept=".midi,.mid" onChange={setMidiFile} /></div>
            </div>
            <div style={{ position: 'relative', width: '630px', maxHeight: '600px', marginLeft: '50%', overflow: 'hidden', transform: 'translateX(-50%)', borderRight: '2px solid #282828', marginTop: '20px' }}>
                <div ref={contentRef} style={{ willChange: 'transform', overflow: 'hidden scroll', maxHeight: '600px', overflowY: 'scroll', whiteSpace: 'nowrap', paddingBottom: '20px' }} ></div>
            </div>
            <div className="fill"></div>
        </StyledMidiVisualization>
    )
}

const play = async (result, divRef) => {
    if (result === undefined)
        return

    if (settings.playing) {
        settings.midi.stop()
        settings.midi.currentTime = 0
        settings.playing = false
        divRef.current.style.overflow = 'hidden scroll'
        divRef.current.scrollTop = divRef.current.scrollHeight
        settings.timerIndex = 0
        settings.msCount = 0
        Object.assign(divRef.current.style, {
            transition: ``,
            transform: ''
        })
        settings.timers.forEach(t => clearTimeout(t))
        return
    }

    settings.playing = true
    const eleHeight = divRef.current.clientHeight
    const scrollHeight = divRef.current.scrollHeight - eleHeight
    divRef.current.style.transform = `translateY(-${scrollHeight}px)`
    divRef.current.style.overflow = ''

    const longestTrack = result.reduce((sum, track) => {
        if (track.bars.length > sum.length) {
            sum = {
                lastBar: track.bars[track.bars.length - 1],
                length: track.bars.length
            }
        }
        return sum
    }, { lastBar: {}, length: 0 })

    const lastBar = longestTrack.lastBar

    const totalTicks = lastBar.tick + lastBar.ticks

    const tempoChanges = result[0].tempos.map((tempo, i) => {
        const temp = {
            bpm: tempo.bpm,
            ticks: tempo.ticks
        }
        if (result[0].tempos[i + 1]) {
            temp.ms = Math.round(result[0].tempos[i + 1].time * 1000) - Math.round(tempo.time * 1000)
        }
        else {
            let count = tempo.ticks

            const msPerBeat = 60000 / Math.round(tempo.bpm)
            const quarterNote = 480

            let msCount = 0

            while (count < totalTicks) {
                msCount += msPerBeat
                count += quarterNote
            }

            temp.ms = msCount
        }
        return temp
    })

    const ms = tempoChanges.reduce((sum, val) => {
        sum.push(val.ms)
        return sum
    }, [])

    const totalMs = ms.reduce((sum, val) => { return sum + val }, 0)

    settings.timerIndex = 0
    settings.msCount = 0

    const speed = settings.speed

    const f = () => {
        Object.assign(divRef.current.style, {
            transition: `transform ${totalMs * speed}ms linear`
        })
        Object.assign(divRef.current.style, {
            transform: `translateY(${eleHeight}px)`
        })
    }

    const marker = document.createElement('div')
    marker.classList.add('marker')

    const firstBarMsPerBeat = 60000 / Math.round(tempoChanges[0].bpm)
    const oneBar = (firstBarMsPerBeat * 2) * speed

    Object.assign(marker.style, {
        height: `2px`,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '600px',
        backgroundColor: 'rgba(100, 100, 255, 0.3)',
        willChange: 'transform',
        transition: `transform ${oneBar}ms linear`
    })

    const prevMarker = document.getElementsByClassName('marker')[0]
    if (prevMarker)
        prevMarker.parentNode.removeChild(prevMarker)

    divRef.current.parentNode.appendChild(marker)

    settings.timers.push(setTimeout(() => {
        marker.style.transform = `translateY(-150px)`
        settings.timers.push(setTimeout(f, oneBar))
        settings.msCount += firstBarMsPerBeat * 2
        if (settings.sound)
            settings.midi.start()
    }, oneBar))

    settings.timers.push(setTimeout(() => {
        Object.assign(divRef.current.style, {
            transition: ``,
            transform: ``
        })
        settings.playing = false
        settings.timers = []
        settings.timerIndex = 0
        settings.msCount = 0
        divRef.current.style.overflow = 'hidden scroll'
    }, totalMs + 100))
}

const setup = async (result, divRef) => {

    if (result === undefined)
        return

    settings.tracks = result
    divRef.current.innerHTML = ''

    const barWidth = 600
    const quarterHeight = 100
    const notes = 22
    const noteWidth = Math.floor(barWidth / notes)
    const octaveWidth = 7 * noteWidth

    const octaveColours = {
        0: 'rgb(155, 134, 190)',
        1: 'rgb(137, 192, 162)',
        2: 'rgb(233, 97, 135)',
        3: 'rgb(233, 97, 135)'
    }

    const offsetMap = {
        1: 0 * noteWidth,
        2: 0.5 * noteWidth,
        3: 1 * noteWidth,
        4: 1.5 * noteWidth,
        5: 2 * noteWidth,
        6: 3 * noteWidth,
        7: 3.5 * noteWidth,
        8: 4 * noteWidth,
        9: 4.5 * noteWidth,
        10: 5 * noteWidth,
        11: 5.5 * noteWidth,
        12: 6 * noteWidth,
        13: 7 * noteWidth
    }

    const noteMap = {
        1: '1',
        2: '1#',
        3: '2',
        4: '2#',
        5: '3',
        6: '4',
        7: '4#',
        8: '5',
        9: '5#',
        10: '6',
        11: '6#',
        12: '7',
        13: '8'
    }

    const bars = []

    result.forEach((track, trackIndex) => {
        track.bars.forEach((bar, index) => {

            const barHeight = Math.ceil((bar.ticks / 480) * quarterHeight)

            // Bar setup
            if (bars[index] === undefined) {
                const div = document.createElement('div')
                div.classList.add('bar')
                Object.assign(div.style, {
                    position: 'relative',
                    display: 'float',
                    clear: 'both',
                    width: `${barWidth}px`,
                    height: `${barHeight}px`,
                    border: '2px solid #282828',
                    borderRight: 'none'
                })

                let line = document.createElement('div')
                Object.assign(line.style, {
                    position: 'absolute',
                    height: `${barHeight}px`,
                    backgroundColor: '#282828',
                    width: `2px`,
                    left: `${7 * noteWidth}px`
                })
                div.appendChild(line)
                line = document.createElement('div')
                Object.assign(line.style, {
                    position: 'absolute',
                    height: `${barHeight}px`,
                    backgroundColor: '#282828',
                    width: `2px`,
                    left: `${14 * noteWidth}px`,
                })
                div.appendChild(line)

                bars[index] = div
            }

            let add = true

            if (settings.onlyMe && trackIndex !== settings.selectedTrack)
                add = false

            if (add) {

                let chordNotes = []

                bar.notes.forEach((note, ni) => {
                    const nDiv = document.createElement('div')
                    nDiv.classList.add('note')

                    const noteStart = ((note.ticks - bar.tick) / bar.ticks) * barHeight
                    const noteHeight = (note.durationTicks / bar.ticks) * barHeight
                    let octave = note.octave - settings.offset

                    let colourIndex = (note.num + settings.steps)
                    if (colourIndex > 12) {
                        colourIndex -= 12
                        octave++
                    }
                    if (colourIndex < 1) {
                        colourIndex += 12
                        octave--
                    }

                    let noteOffset = octave * (settings.overview ? octaveWidth : 0)

                    noteOffset += offsetMap[colourIndex]

                    Object.assign(nDiv.style, {
                        position: 'absolute',
                        height: `${noteHeight - (4 + 5)}px`,
                        width: `${noteWidth - 4}px`,
                        bottom: `${noteStart + 2}px`,
                        left: `${noteOffset + 2}px`,
                        background: octaveColours[octave],
                        zIndex: 100
                    })

                    let noteNumber = noteMap[colourIndex]

                    const wasLower = colourIndex === 1 && note.prevOctave < note.octave
                    const wasSameOctaveC = chordNotes[0] && chordNotes[0].colourIndex === 8 && colourIndex === 1 && chordNotes[0].octave === octave - 1
                    const isHighestC = octave === 4 && colourIndex === 1

                    let nextColourIndex = false
                    let nextOctave = false
                    if (note.nextNote) {
                        nextColourIndex = note.nextNote.num + settings.steps
                        nextOctave = note.nextNote.octave - settings.offset
                        if (nextColourIndex > 12) {
                            nextColourIndex -= 12
                            nextOctave++
                        }
                        if (nextColourIndex < 1) {
                            nextColourIndex += 12
                            nextOctave--
                        }
                    }

                    const stayOne = note.nextNote ? (note.nextNote.ticks === note.ticks && nextColourIndex <= 10 && nextOctave === octave) : false

                    if ((wasLower || wasSameOctaveC || isHighestC) && stayOne === false) {
                        noteNumber = 8
                        octave--
                        nDiv.style.background = octaveColours[octave]
                        if (settings.overview === false)
                            nDiv.style.left = `${noteOffset + octaveWidth + 2}px`
                    }

                    if (trackIndex !== settings.selectedTrack) {
                        nDiv.style.background = `#575757`
                        nDiv.style.zIndex = 0
                    }

                    if (chordNotes.length > 0 && chordNotes[0].ticks === note.ticks && settings.overview === false) {
                        const sameNote = chordNotes.reduce((sum, val) => {
                            if (sum) return sum
                            if (val.colourIndex === noteNumber) return val
                            return false
                        }, false)
                        chordNotes.push({
                            colourIndex: noteNumber,
                            ticks: note.ticks,
                            octave: octave,
                            ele: nDiv
                        })
                        if (sameNote) {
                            sameNote.ele.style.width = `${(noteWidth / 2 - 2)}px`
                            nDiv.style.width = `${(noteWidth / 2 - 2)}px`
                            nDiv.style.left = `${noteOffset + (noteWidth / 2) + 1}px`
                        }
                    }
                    else {
                        chordNotes = [{
                            colourIndex: noteNumber,
                            ticks: note.ticks,
                            octave: octave,
                            ele: nDiv
                        }]
                    }

                    nDiv.innerHTML = `<span style="position:absolute;bottom:0px;text-align:center;transform:translateX(-50%)">${noteNumber}</span>`
                    Object.assign(nDiv.style, {
                        textShadow: '-1px 1px 0 #000, 1px 1px #000, 1px -1px 0 #000, -1px -1px 0 #000',
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: '18px',
                        lineWidth: `${noteHeight - 4}px`
                    })

                    bars[index].appendChild(nDiv)
                })
            }
        })
    })

    bars.forEach(bar => { divRef.current.prepend(bar) })
}

const nextTimeSig = (timesignatures, index, currTick) => {
    return (timesignatures[index].ticks <= currTick);
}

const nextTempo = (tempos, index, currTick) => {
    return (tempos[index].ticks <= currTick);
}

const transposeNote = (note) => {
    const notes = { 'C': 1, 'C#': 2, 'D': 3, 'D#': 4, 'E': 5, 'F': 6, 'F#': 7, 'G': 8, 'G#': 9, 'A': 10, 'A#': 11, 'B': 12 }
    return notes[note.pitch]
}

const timeSigUnits = (timesignature, ppq) => {
    let barUnit = ppq;
    let ticksPerBar = ppq * 4;
    switch (timesignature[1]) {
        case 1: barUnit = ppq * 4; break;
        case 2: barUnit = ppq * 2; break;
        case 4: barUnit = ppq; break;
        case 8: barUnit = ppq / 2; break;
        case 16: barUnit = ppq / 4; break;
        case 32: barUnit = ppq / 8; break;
        default: break;
    }

    ticksPerBar = barUnit * timesignature[0];

    return { unit: barUnit, perBar: ticksPerBar };
}

const parseFile = async (midi) => {
    midi = await Midi.fromUrl(midi)

    midi.tracks.forEach(track => {
        if (track.instrument.number === 112) {
            track.instrument.number = 1
            track.instrument.name = 'bright_acoustic_piano'
        }
        if (track.instrument.number === 14) {
            track.instrument.number = 1
            track.instrument.name = 'bright_acoustic_piano'
        }
    })

    const ppq = midi.header.ppq;
    const multipleSignatures = midi.header.timeSignatures.length > 1;
    const tempos = midi.header.tempos;

    const keyToNum = { C: 1, D: 2, E: 3, F: 4, G: 5, A: 6, B: 7 };

    midi.tracks = midi.tracks.reduce((sum, val) => {
        if (val.notes && val.notes.length > 0)
            sum.push(val);
        return sum;
    }, []);

    const tracks = midi.tracks.map(track => {
        let currTimeSig = midi.header.timeSignatures[0].timeSignature;
        let timeSigIndex = 1;
        let tempoIndex = 1;
        let units = timeSigUnits(currTimeSig, ppq);
        let ticksPerBar = units.perBar;
        let barUnit = units.unit;

        let currTick = 0;
        let bars = [{ notes: [], tick: currTick, ticks: ticksPerBar, unit: barUnit, timeSignatureChange: [currTimeSig] }];
        const notes = track.notes;

        let minOctave = 1337;
        let maxOctave = -1337;

        notes.forEach((n, i) => {
            n.isSharp = n.pitch.indexOf('#') !== -1;
            n.num = transposeNote(n);
            n.pitch = n.pitch.substr(0, 1);
            n.prevOctave = notes[i - 1] ? notes[i - 1].octave : n.octave
            n.nextOctave = notes[i + 1] ? notes[i + 1].octave : n.octave
            n.nextNote = notes[i + 1] ? notes[i + 1] : false
        })

        notes.sort((a, b) => {
            if (a.ticks - b.ticks !== 0) return a.ticks - b.ticks;
            if (a.octave - b.octave !== 0) return a.octave - b.octave;
            return keyToNum[a.pitch] - keyToNum[b.pitch];
        });

        let prevTick = 0;

        // Setup notes
        notes.forEach((note, index) => {
            if (index === 0) {
                prevTick = note.ticks;
            }
            prevTick = note.ticks;
            if (tempos[tempoIndex] && nextTempo(tempos, tempoIndex, prevTick)) {
                note.tempo = Math.floor(tempos[tempoIndex].bpm);
                tempoIndex++;
                while (tempos[tempoIndex] && nextTempo(tempos, tempoIndex, prevTick)) {
                    note.tempoChange = Math.floor(tempos[tempoIndex].bpm);
                    tempoIndex++;
                }
            }
            while (currTick <= note.ticks) {
                currTick += ticksPerBar;
                if (multipleSignatures && midi.header.timeSignatures[timeSigIndex] && nextTimeSig(midi.header.timeSignatures, timeSigIndex, currTick)) {
                    units = timeSigUnits(midi.header.timeSignatures[timeSigIndex].timeSignature, ppq);
                    currTimeSig = midi.header.timeSignatures[timeSigIndex];
                    timeSigIndex++;
                    ticksPerBar = units.perBar;
                    barUnit = units.unit;
                    bars.push({ notes: [], tick: currTick, ticks: ticksPerBar, unit: barUnit, timeSignature: currTimeSig });
                }
                else
                    bars.push({ notes: [], tick: currTick, ticks: ticksPerBar, unit: barUnit });
            }
            const barIndex = bars.findIndex(x => note.ticks >= x.tick && note.ticks < (x.tick + x.ticks));
            bars[barIndex].notes.push(note);

            if (note.octave > maxOctave) maxOctave = note.octave;
            if (note.octave < minOctave) minOctave = note.octave;
        })

        return {
            name: track.instrument.name,
            bars: bars,
            tempos: tempos
        };
    })

    return tracks
}

export default MidiVisualization