import React from 'react'
import { StyledFAQ } from './faq.styled'

const FAQ = () => {

    const faq = [
        {
            title:`* Powerina has the power`,
            description:`IP ban anyone that doesn't use common sense.`
        },
        {
            title:`* DMCA / Copyright`,
            description:`I'll remove any material upon request if link to the song on this website and proper notice is provided.`
        }
    ]

    return (
        <StyledFAQ>
            {
                faq.map((f, index) => {
                    return (
                        <div key={`faq-${index}`}>
                            <h2>{f.title}</h2>
                            <p>{f.description}</p>
                        </div>
                    )
                })
            }
        </StyledFAQ>
    )
}

export default FAQ