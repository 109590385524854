import styled from 'styled-components'

export const StyledMenu = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: top;
  background: rgb(50, 50, 50);
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: calc(100% - 140px);
  min-height: 600px;
  text-align: left;
  padding: 20px;
  padding-top: 120px;
  top: 0;
  left: 0;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  z-index: 5;
  box-shadow: ${({ open }) => open ? '10px 0px 50px black' : ''};

  a {
    user-select: none;
    cursor: pointer;
    font-size: 30px;
    text-transform: uppercase;
    padding: 20px 0;
    font-weight: bold;
    letter-spacing: 2px;
    color: rgb(210,210,210);
    
    text-decoration: none;
    border-bottom: 0px white solid;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
    }
  }

  div {
    user-select: none;
    cursor: pointer;
    font-size: 30px;
    text-transform: uppercase;
    padding: 20px 0;
    font-weight: bold;
    letter-spacing: 2px;
    color: rgb(210,210,210);
    
    text-decoration: none;
    border-bottom: 0px white solid;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
    }
  }
`;