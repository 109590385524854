import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StyledViewer } from './viewer.styled'
import Songdetails from './songdetails/Songdetails'
import Firebase from '../../firebase'

const Viewer = () => {
    const [song, setSong] = useState({formatting:{tab:''}})
    const { songId } = useParams()
    const tabRef = useRef()

    useEffect(() => {
        const firebase = new Firebase()
        return firebase.app.firestore().collection('songs').doc(songId).onSnapshot(async snap => {
            console.log(snap.metadata.fromCache ? 'Cached Song' : 'Updated Song')
            const data = snap.data()
            snap.exists ? setSong(data) : setSong({formatting:{tab:'Song has been deleted'}})
            document.title = `Songbook - ${data ? data.name : 'deleted'}`
        })
    }, [songId])

    useEffect(() => {
        let html = tabRef.current.innerText
        const symbols = [
            {regex:'(\\|)', colour:'lightgreen'},
            {regex:'(\\[.*?\\])', colour:'#42c3ff'},
            {regex:'(\\(.*?\\))', colour:'#ff5c5c'}
        ]

        symbols.forEach((symbol) => {
            const regEx = new RegExp(`${symbol.regex}`, "ig")
            html = html.replace(regEx, function (match) {
                return `<span style="color:${symbol.colour};">${match}</span>`
            })
        })
        tabRef.current.innerHTML = html
    }, [song, tabRef])

    return (
        <StyledViewer>
            <div className="fillTop"></div>
            <Songdetails song={song}></Songdetails>
            <pre className="tab" ref={tabRef} >
                {
                    song.formatting.tab
                }
            </pre>
            <div className="fill"></div>
        </StyledViewer>
    )
}

export default Viewer