import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Songbook from './songbook'

if (!String.linkify) {
  // eslint-disable-next-line
  String.prototype.linkify = function () {

    // http://, https://, ftp://
    // eslint-disable-next-line
    var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

    // www. sans http:// or https://
    // eslint-disable-next-line
    var pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

    // Email addresses
    // eslint-disable-next-line
    var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

    return this
      .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
      .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
      .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
  };
}

ReactDOM.render(
  <React.StrictMode>
    <Songbook />
  </React.StrictMode>,
  document.getElementById('root')
)

if (true) {
  navigator.serviceWorker.register('https://gw2-songbook.com/service-worker.js', {
    scope: './',
  });
}
