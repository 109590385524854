import React, { useEffect, useState } from 'react'
import { StyledList } from './list.styled'
import { Songlist } from '../../components'
import Firebase from '../../firebase'

const List = () => {
    const [songs, setSongs] = useState([])
    const cached_list = 'gw2-songbook/songListCache.json'
    const cache_name = 'gw2-songbook'

    useEffect(() => {
        const firebase = new Firebase()

        const lastFetch = localStorage.getItem('lastFetch') || 0
        const now = new Date().getTime()
        const shouldFetch = (now - lastFetch) > (300 * 1000)

        window.caches.match(cached_list).then(async cache => {
            if (cache && !shouldFetch) {
                console.log('Cached Songlist')
                const cachedSongs = await cache.json()
                setSongs(cachedSongs)
            }
            else {
                firebase.app.storage().ref('songlist.json').getDownloadURL().then(url => {
                    const xhr = new XMLHttpRequest()
                    xhr.onreadystatechange = (e) => {
                        if (e.target.readyState === 4 && [200, 204].indexOf(e.target.status) !== -1) {
                            const songList = JSON.parse(e.target.response)
                            const songData = Object.keys(songList).map((id) => {
                                if (!songList[id].createdDate)
                                    songList[id].createdDate = songList[id].lastModified
                                return songList[id]
                            })
                            songData.sort((a, b) => { return b.createdDate - a.createdDate })

                            window.caches.open(cache_name).then(async store => {
                                const songBlob = new Blob([JSON.stringify(songData)])
                                store.put(cached_list, new Response(songBlob, { type: "application/json" }), { status: 200 }).then(() => {
                                    localStorage.setItem('lastFetch', new Date().getTime())
                                    console.log('Updated SongList')
                                }).catch(console.log)
                            })
                            setSongs(songData)
                        }
                    }
                    xhr.open('GET', url)
                    xhr.send()
                })
            }
        })
    }, [])

    return (
        <StyledList>
            <Songlist songs={songs} sorting={true} />
        </StyledList>
    )
}

export default List