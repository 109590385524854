import React from 'react'
import { StyledMenu } from './Menu.styled'
import { NavLink as Link } from 'react-router-dom'
import Firebase from '../../firebase'

const Menu = ({ open, setOpen, signIn }) => {
    const firebase = new Firebase()
    const auth = firebase.app.auth()
    const isAnon = localStorage.getItem('anon')

    return (
        <StyledMenu open={open}>
            <Link to="/" onClick={() => setOpen(false)}>Songbook</Link>
            <Link to="/favourites" onClick={() => setOpen(false)}>Powerina's <br />Favourites</Link>
            {
                !isAnon && <Link to="/drafts" onClick={() => setOpen(false)}>My Drafts</Link>
            }
            {
                !isAnon && <Link to="/post" onClick={() => setOpen(false)}>Post a Song</Link>
            }
            <Link to="/visualizer" onClick={() => setOpen(false)}>Visualizer</Link>
            <Link to="/faq" onClick={() => setOpen(false)}>FAQ</Link>
            <Link to="/about" onClick={() => setOpen(false)}>About</Link>
            {
                auth.currentUser ? <a href="/" className="signOut" onClick={async () => {
                    setOpen(false)
                    localStorage.setItem('authenticated', '')
                    localStorage.setItem('anon', 1)
                    auth.signOut()
                }}>Sign Out</a> : <div href="/" className="signIn" onClick={async () => {
                    signIn()
                }}>Sign In</div>
            }
        </StyledMenu>
    )
}

export default Menu
