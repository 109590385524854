import styled from 'styled-components'

export const StyledMidiPost = styled.div`
position: relative;
display:flex;
flex-direction: column;
justify-content: center;
text-align: left;
z-index: 0;
width:100%;
min-width: 600px;

.tab {
    float:left;
    border: 1px black solid;
    min-height:390px;
    min-width: 600px;
    width: 75%;
    margin-left: calc(12.5% - 20px);
    padding: 20px 20px;
    margin-top:30px;
    background-color: rgb(40, 40, 40);
    white-space: pre-wrap;
    color:rgb(200, 200, 200)
}

.track {
    padding-bottom:10px;

    .instrumentName {
        outline: none;
    }

    .bars {
        padding: 20px 0px;
        .bar {
            display:inline-block;
        }
    }
}

.tab:focus {
    outline: none;
    border: 1px rgb(70, 70, 70) solid;
}
.fill {
    float:left;
    clear:both;
    height:20px;
    width:100%;
}
.fillTop {
    float:left;
    clear:both;
    height:20px;
    width:100%;
}

.controls {
    position:relative;
    height:105px;
    width:75%;
    min-width:600px;
    margin-left: calc(12.5% - 20px);
    padding: 10px 20px;
    background-color: rgb(40, 40, 40);
    border 1px rgb(60, 60, 60) solid;
    white-space: nowrap;
    overflow:hidden;

    .introBars {
        position:relative;
        outline: none;
        font-size:20px;
        width:50px;
        border:1px solid black;
        background-color: rgb(0, 0, 0);
        color: rgb(200, 200, 200);
        margin-top:-5px;
    }

    .octave {
        position:relative;
        outline: none;
        font-size:20px;
        width:30px;
        border:1px solid black;
        background-color: rgb(0, 0, 0);
        color: rgb(200, 200, 200);
        margin-top:-5px;
    }

    .barsPerRow {
        position:relative;
        outline: none;
        font-size:20px;
        width:40px;
        border:1px solid black;
        background-color: rgb(0, 0, 0);
        color: rgb(200, 200, 200);
        margin-top:-5px;
    }

    .text {
        height:25px;
        display:inline-block;
        float: left;
        font-family: Verdana;
        outline: none !important;
        margin-right:15px;
        margin-bottom: 4px;
        .key {
            margin-right:5px;
        }

        .value {
            outline: none;
            white-space: nowrap;
            background-color: rgb(15, 15, 15);
            padding:5px;
        }
    }

    .button {
        background-color: rgb(50, 50, 50);
        border: 1px black solid;
        font-family:Verdana;
        user-select: none;
        height: 25px;
        line-height: 25px;
        text-align:center;
        min-width:75px;

        &.save {
            position:absolute;
            right:5px;
            top:4px;
            padding:5px;
        }

        &.delete {
            position:absolute;
            right:5px;
            bottom:5px;
            padding: 5px;
        }

        &.create {
            position:absolute;
            right:5px;
            top:25px;
            padding: 5px;
            height:63px;
            line-height:63px;
        }

        &.mp3Drop {
            position:absolute;
            right: 110px;
            height:63px;
            top:25px;
            line-height:63px;
            padding:5px;
        }

        &.midiDrop {
            position:absolute;
            right: 213px;
            height:63px;
            top:25px;
            line-height:63px;
            padding:5px;
        }

        .input {
            opacity: 0.0; 
            position: absolute; 
            top: 0; 
            left: 0; 
            bottom: 0; 
            right: 0; 
            width: 100%; 
            height:100%;
            &:hover {
                cursor: pointer;
                background-color:rgb(30, 30, 30);
            }
        }

        &:hover {
            cursor: pointer;
            background-color:rgb(30, 30, 30);
        }

        &:active:hover {
            background-color:rgb(10, 10, 10);
        }
    }
}
`;