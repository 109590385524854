import React, { useEffect, useState } from 'react'
import { StyledPost } from './post.styled'
import { MidiPost, RegularPost } from '../../components'
import Firebase from '../../firebase'
import { useParams } from 'react-router-dom'

const Post = () => {
    const { songId } = useParams()
    const [post, setPost] = useState({
        author:'Author Anon',
        formatting:{
            bars:[],
            instruments:[],
            midiInstruments:{},
            octaveShift:0,
            tempo:120,
            timeSignature: {
                base:4,
                beats:4
            },
            tab:''
        },
        hasMp3: false,
        isMidi: false,
        lastModified: undefined,
        midi: undefined,
        mp3: undefined,
        name: 'Song Name',
        public: false,
        secret: 'public'
    })

    useEffect(() => {
        if (songId !== undefined) {
            const firebase = new Firebase()
            firebase.app.firestore().collection('songs').doc(songId).get().then(doc => {
                const song = doc.data()
                setPost(song)
            })
        }
    }, [songId])

    return (
        <StyledPost>
            {
                post.isMidi ? (<MidiPost post={post} />) : (<RegularPost post={post} setPost={setPost} />)
            }
        </StyledPost>
    )
}

export default Post