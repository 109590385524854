import React, { useState, useEffect } from 'react'
import { StyledDrafts } from './drafts.styled'
import { Songlist } from '../../components'
import Firebase from '../../firebase'

const Drafts = () => {
    const [songs, setSongs] = useState([])

    useEffect(() => {
        const firebase = new Firebase()
        const auth = firebase.app.auth()
        return firebase.app.firestore().collection('users').doc(`${auth.currentUser.uid}`).collection('songs').onSnapshot(async snap => {
            console.log(snap.metadata.fromCache ? 'Cached Drafts' : 'Updated Drafts')
            const data = await Promise.all(snap.docs.map((doc) => {
                const docData = doc.data()
                docData.id = doc.id
                return docData
            }))
            data.sort((a, b) => { return b.lastModified - a.lastModified })
            setSongs(data)
        })
    }, [])

    return (
        <StyledDrafts>
            <Songlist songs={songs}/>
        </StyledDrafts>
    )
}

export default Drafts