import React, { useState, useEffect } from 'react'
import { StyledFavourites } from './favourites.styled'
import { Songlist } from '../../components'
import Firebase from '../../firebase'

const Favourites = () => {
    const [songs, setSongs] = useState([])

    useEffect(() => {
        const firebase = new Firebase()
        return firebase.app.firestore().collection('songs').where('favourite', '==', true).onSnapshot(async snap => {
            console.log(snap.metadata.fromCache ? 'Cached Favourites' : 'Updated Favourites')
            const data = await Promise.all(snap.docs.map((doc) => {
                const docData = doc.data()
                docData.id = doc.id
                return docData
            }))
            setSongs(data)
        })
    }, [])

    return (
        <StyledFavourites>
            <Songlist songs={songs}/>
        </StyledFavourites>
    )
}

export default Favourites